"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import { BsArrowLeft } from "@react-icons/all-files/bs/BsArrowLeft";
import {
  Card,
  CardContent,
} from "@irbano/react-components/dist/components/ui/card";

type ErrorPageProps = {
  title?: string;
  subTitle?: string;
  code?: number;
  description?: string;
  buttonText?: string;
  onClickButton?: () => void;
  buttonLinkUrl?: string;
};

function ErrorPage({
  title,
  code = 500,
  subTitle,
  description,
  buttonText,
  onClickButton,
  buttonLinkUrl,
}: ErrorPageProps) {
  const renderButton = () => {
    if (buttonLinkUrl) {
      return (
        <Link
          href={buttonLinkUrl}
          className="flex items-center gap-3 text-white py-4 px-8 rounded-full bg-primary-400 cursor-pointer z-20"
        >
          <BsArrowLeft size={15} />
          <div className="text-xs font-medium">{buttonText}</div>
        </Link>
      );
    }
    if (onClickButton) {
      return (
        <div
          className="flex items-center gap-3 text-white py-4 px-8 rounded-full bg-primary-400 cursor-pointer z-20"
          onClick={onClickButton}
        >
          <BsArrowLeft size={15} />
          <div className="text-xs font-medium">{buttonText}</div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="flex w-full justify-center items-center bg-white p-8">
      <Card className="flex w-full items-center justify-center max-w-2xl">
        <CardContent className="flex flex-col justify-center items-center">
          <Image
            src="/logos/colored-logo.png"
            width={781}
            height={322}
            alt="Redilify by Irbano"
            className="w-40 max-w-full h-auto object-contain"
          />
          <div className="mx-auto mt-2 max-w-2xl text-center">
            <p className="text-base font-semibold leading-8 text-gray-600">
              {code}
            </p>
            {title ? (
              <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                {title}
              </h1>
            ) : null}

            {subTitle ? (
              <p className="mt-4 text-base leading-7 text-gray-600 sm:text-lg sm:leading-8">
                {subTitle}
              </p>
            ) : null}
            {description ? (
              <p className="text-sm z-20 max-w-xl">{description}</p>
            ) : null}
          </div>
          <div className="mt-10 flex justify-center">{renderButton()}</div>
        </CardContent>
      </Card>
    </div>
  );
}

export default ErrorPage;
